.form-check {
  @apply relative block;
}

.form-check-label {
  @apply inline-flex items-center bg-background w-full border-1 rounded-md p-3 mb-3 cursor-pointer;
  &:hover {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.4);
  }
}

.form-radio > *:last-child .form-check-label {
  margin-bottom: 0px !important;
}

.radio-selected .form-check-label {
  box-shadow: 0 0 0 2px black;
}

.has-error label.form-check-label {
  box-shadow: none;
}

.form-check-input {
  @apply p-0 inline-block align-middle flex-shrink-0 bg-white border-1;
  @apply rounded border-gray-300 text-black;
  @apply mr-2;
  &:not([type='radio']) {
    @apply appearance-none;
  }
  accent-color: black;
  color-adjust: exact;
  background-origin: border-box;
  user-select: none;
  height: theme('spacing.4');
  width: theme('spacing.4');

  &:focus {
    // outline-offset: theme('outline.none[1]');
    // box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    @apply outline-none border-black;
  }

  &[type='radio'] {
    @apply rounded-full;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid darkgray;
    border-radius: 50%;
    outline: none;
    &:not(:checked) {
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
  }

  &[type='checkbox']:indeterminate,
  &[type='checkbox']:checked,
  &[type='radio']:checked {
    @apply border-transparent bg-current bg-center bg-no-repeat;
    background-size: 100% 100%;
  }

  &[type='checkbox']:indeterminate,
  &[type='checkbox']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }

  &[type='radio']:checked {
    -webkit-appearance: auto;
  }

  &[type='checkbox']:checked:hover,
  &[type='checkbox']:checked:focus,
  &[type='radio']:checked:hover,
  &[type='radio']:checked:focus {
    @apply border-transparent bg-current;
  }

  &:disabled ~ span {
    @apply text-gray-600;
  }
}
