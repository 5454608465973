/* Base class
Requires one of the contextual, color modifier classes for `color` and
`background-color`.
 */

.badge {
  @apply inline-flex  py-px px-1.5 font-bold text-center whitespace-nowrap items-center rounded transition-colors;
  font-size: 75%;

  &:empty {
    @apply hidden;
  }

  &.badge-hover {
    @apply cursor-pointer;
  }
}

a .badge {
  &:hover,
  &:focus {
    @apply no-underline;
  }
}

/* Quick fix for badges in buttons */
.btn .badge {
  @apply relative -top-px;
}

/* Pill badges
Make them extra rounded with a modifier to replace v3's badges.
 */
.badge.bg-primary {
  @apply text-white border-primary;
}

a .badge.bg-primary,
.badge-hover.bg-primary {
  &:hover,
  &:focus {
    @apply hover:text-white hover:bg-gray-800 focus:bg-gray-800 hover:border-gray-800 focus:border-gray-800 focus:text-gray-800;
  }
}

.badge.bg-secondary {
  @apply text-white border-secondary;
}

a .badge.bg-secondary,
.badge-hover.bg-secondary {
  &:hover,
  &:focus {
    @apply hover:text-white hover:bg-secondary focus:bg-secondary hover:border-secondary focus:border-secondary focus:text-secondary;
  }
}

.badge.bg-success {
  @apply text-white bg-green-600 border-green-600;
}

a .badge.bg-success,
.badge-hover.bg-success {
  &:hover,
  &:focus {
    @apply hover:text-white hover:bg-green-800 focus:bg-green-800 hover:border-green-800 focus:border-green-800 focus:text-green-800;
  }
}

.badge.bg-danger {
  @apply text-white bg-red-600 border-red-600;
}

a .badge.bg-danger,
.badge-hover.bg-danger {
  &:hover,
  &:focus {
    @apply hover:text-white hover:bg-red-800 focus:bg-red-800 hover:border-red-800 focus:border-red-800 focus:text-red-800;
  }
}

.badge.bg-warning {
  @apply text-white bg-yellow-600 border-yellow-600;
}

a .badge.bg-warning,
.badge-hover.bg-warning {
  &:hover,
  &:focus {
    @apply hover:text-white hover:bg-yellow-800 focus:bg-yellow-800 hover:border-yellow-800 focus:border-yellow-800 focus:text-yellow-800;
  }
}

.badge.bg-info {
  @apply text-white bg-blue-600 border-blue-600;
}

a .badge.bg-info,
.badge-hover.bg-info {
  &:hover,
  &:focus {
    @apply hover:text-white hover:bg-blue-800 focus:bg-blue-800 hover:border-blue-800 focus:border-blue-800 focus:text-blue-800;
  }
}

.badge.bg-light {
  @apply text-gray-600 bg-gray-200 border-gray-200;
}

a .badge.bg-light,
.badge-hover.bg-light {
  &:hover,
  &:focus {
    @apply hover:text-gray-800 hover:bg-gray-300 focus:bg-gray-300 hover:border-gray-300 focus:border-gray-300 focus:text-gray-800;
  }
}

.badge.bg-dark {
  @apply text-white bg-gray-600 border-gray-600;
}

a .badge.bg-dark,
.badge-hover.bg-dark {
  &:hover,
  &:focus {
    @apply hover:text-white hover:bg-gray-800 focus:bg-gray-800 hover:border-gray-800 focus:border-gray-800 focus:text-gray-800;
  }
}
