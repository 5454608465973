.tw-tabs {
  &__header {
    @apply overflow-auto;

    &-wrapper {
      @apply relative flex items-center pt-1 px-1;
    }
    &-border {
      @apply  border-b-1 border-gray-300 absolute bottom-0 left-0 right-0 z-1;
    }
  }

  &__button {
    @apply flex items-center border-1 border-b-0 border-gray-300 relative outline-none focus:outline-none bg-gray-100 text-gray-700 hover:text-secondary focus:text-secondary px-4 py-2;

    &-label {
      @apply text-sm whitespace-nowrap;
    }
    &-icon {
      @apply mr-2 -ml-1 -mt-px;
    }

    &-border {
      @apply border-transparent z-1 absolute top-0 right-0 left-0 border-t-2;
      &.-active {
        @apply border-primary;
      }
    }
    &-wrapper {
      @apply relative  mr-1;

      &:last-child{
        @apply mr-0;
      }

      &.-active {
        @apply z-2;
      }
      &.-back{
        @apply -ml-2 mr-0;
      }
    }

    &.-active {
      @apply text-primary bg-white;
    }

    &.-reverse {
      @apply bg-white;
      &.-active {
        @apply bg-gray-100;
      }
    }

    &.-back {
      @apply text-secondary bg-white px-2 border-transparent;

      .tabs__button-icon {
        @apply m-0;
      }
    }
  }
}