.panel {
  .panel-body {
    height: auto;
    overflow: visible;
    margin-bottom: 16px;
    &.panel-collapsed {
      height: 280px;
      overflow: hidden;
      &:before {
        content: '';
        border-radius: 6px;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 30;
        background: linear-gradient(transparent 150px, white);
      }
    }
  }
}
