.pagination {
  @apply inline-flex rounded justify-evenly w-full;
}

.page-item {
  @media screen and (max-width: 768px) {
    @apply text-xs;
  }
  &.active:before {
    @apply bg-primary;
  }

  &.active .page-link {
    // color: rgba(59, 104, 175, 1) !important;
    color: black !important;
    font-weight: bold;
  }

  &.disabled .page-link {
    @apply text-gray-400 pointer-events-none cursor-auto;
    color: rgba(156, 163, 175, 1) !important;
  }
  @media screen and (max-width: 768px) {
    @apply text-base;
    .page-link {
      display: none;
    }
    &.active .page-link {
      display: block;
    }
  }
}

.pagination-group {
  @apply flex;
  .form-group {
    @apply m-0;
  }
}

.page-link {
  @apply relative text-gray-400;
  color: rgba(156, 163, 175, 1) !important;
  background-color: transparent !important;

  &:hover {
    @apply z-2 cursor-auto no-underline;
  }

  &:focus {
    @apply z-2 outline-none;
  }
}

// Numbers in front of the nav bar
nav > ul li {
  position: relative;
  margin-bottom: 0;
  list-style-type: none;
  &:before {
    // content: '';
    // padding-top: 4px;
    // left: -42px;
    // top: 4px;
    // display: block;
    // width: 32px;
    // height: 32px;
    // border-radius: 8px;
    // color: white;
    // padding-left: 12px;
    // line-height: 1.5em;
    // @apply bg-primary absolute;
    display: none;
  }
  @media screen and (max-width: 768px) {
    position: relative;
    line-height: 2.5em;
    list-style-type: none;
    &:before {
      // @apply bg-primary absolute mt-0;
      // content: '';
      // left: -42px;
      // top: 4px;
      // padding-top: 4px;
      // display: inline-block;
      // width: 32px;
      // height: 32px;
      // border-radius: 8px;
      // color: white;
      // padding-left: 12px;
      // line-height: 1.5em;
      display: none;
    }
  }

  &:nth-child(1):before {
    content: '1';
  }
  &:nth-child(2):before {
    content: '2';
  }
  &:nth-child(3):before {
    content: '3';
  }
  &:nth-child(4):before {
    content: '4';
  }
  &:nth-child(5):before {
    content: '5';
  }
  &:nth-child(6):before {
    content: '6';
  }
  &:nth-child(7):before {
    content: '7';
  }
  &:nth-child(8):before {
    content: '8';
  }
}
