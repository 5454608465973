@import '~formiojs/dist/formio.full.css';
@import './tailwind.css';
/* @import "./fonts/source-sans-pro/index.css";
@import "./fonts/inconsolata/index.css";
@import "./fonts/bxicons/index.css"; */
@import './styles/index.css';

body {
  @apply text-custom-black bg-appBackground;
}

br {
  display: none;
}

h1 {
  @apply text-2xl md:text-3xl font-semibold py-4 font-title;
}

h3 {
  @apply text-xl font-semibold py-3;
}

h4 {
  @apply text-sm md:text-base font-semibold py-3;
}
p.paragraph-large {
  font-size: 1.1em;
}

.formio-component-checkbox.no-background {
  background-color: transparent !important;
  padding-left: 0px;
  padding-right: 0px;
}

.formio-component-checkbox.no-background .form-check-label {
  background-color: white !important;
}

.formio-choices.form-group {
  padding-bottom: 0px;
}

p,
li {
  @apply text-base mb-4 font-normal;
}

ul {
  @apply pb-2;
  li {
    @apply mb-2;
  }
}

ul {
  @apply pb-2;
  li {
    ul {
      @apply mt-2 list-disc pb-1;
      li {
        @apply ml-5;
      }
    }
  }
}

p.info-text {
  @apply text-info text-sm md:text-base;
  position: relative;
  padding-left: 30px;
  margin-bottom: 16px;
  &:before {
    position: absolute;
    content: url('../svg/info-blue.svg');
    left: 0px;
    top: 0px;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    line-height: 1px;
  }
}

p.check-text {
  @apply text-sm md:text-base;
  color: #10b981;
  position: relative;
  padding-left: 30px;
  padding-bottom: 0;
  margin-bottom: 0px;
  &:before {
    position: absolute;
    content: url('../svg/check.svg');
    left: 0px;
    top: 0px;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    line-height: 1px;
  }
}

p.warning-text {
  @apply text-sm md:text-base;
  color: #b96710;
  position: relative;
  padding-left: 30px;
  padding-bottom: 0;
  margin-bottom: 0px;
  &:before {
    position: absolute;
    content: url('../svg/warning.svg');
    left: 0px;
    top: 0px;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    line-height: 1px;
  }
}

p.info-box {
  @apply text-info text-sm md:text-base;
  border: 1px solid #1067eb;
  padding: 1em;
  margin-top: 20px;
  border-radius: 10px;
  background-color: rgba(16, 103, 235, 0.05);
  position: relative;
  padding-left: 3em;
  margin-bottom: 0px;
  &:before {
    position: absolute;
    content: url('../svg/info-blue.svg');
    left: 1em;
    top: 1em;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    line-height: 1px;
  }
}

p.check-box {
  @apply text-sm md:text-base;
  color: #10b981;
  border: 1px solid #10b981;
  padding: 1em;
  margin-top: 20px;
  border-radius: 10px;
  background-color: rgba(16, 185, 129, 0.05);
  position: relative;
  padding-left: 3em;
  margin-bottom: 0px;
  &:before {
    position: absolute;
    content: url('../svg/check.svg');
    left: 1em;
    top: 1em;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    line-height: 1px;
  }
}

p.warning-box {
  @apply text-sm md:text-base;
  color: #b96710;
  border: 1px solid #b96710;
  padding: 1em;
  margin-top: 20px;
  border-radius: 10px;
  background-color: rgba(185, 103, 16, 0.05);
  position: relative;
  padding-left: 3em;
  margin-bottom: 0px;
  &:before {
    position: absolute;
    content: url('../svg/warning.svg');
    left: 1em;
    top: 1em;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    line-height: 1px;
  }
}
p.error-box {
  @apply text-sm md:text-base;
  color: #d0342c;
  border: 1px solid #d0342c;
  padding: 1em;
  margin-top: 20px;
  border-radius: 10px;
  background-color: rgba(208, 52, 44, 0.05);
  position: relative;
  padding-left: 3em;
  margin-bottom: 0px;
  &:before {
    position: absolute;
    content: url('../svg/error-red.svg');
    left: 1em;
    top: 1em;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    line-height: 1px;
  }
}

.col-md-3 {
  @media screen and (max-width: 768px) {
    flex: 0 0 35% !important;
    max-width: 35% !important;
    padding-right: 0px;
    padding-left: 15px;
  }
}

.col-md-9 {
  @media screen and (max-width: 768px) {
    flex: 0 0 65% !important;
    max-width: 65% !important;
  }
}

.error-message-box {
  @apply rounded-lg p-4;
  background-color: rgba(255, 223, 223, 1);

  & h3 {
    color: rgba(193, 15, 15, 1);
  }
  & p {
    margin-bottom: 16px;
  }
  & .link {
    color: rgba(193, 15, 15, 1);
  }
}

// Error message in the modal
.error-message-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  & p {
    margin-bottom: 16px;
  }
  & .link {
    color: rgba(193, 15, 15, 1);
  }
}

.modal-content {
  text-align: center;
  background-color: white;
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 32px;
  border: 1px solid #888;
  width: 300px;
}

button.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  &:before {
    content: url('../svg/close.svg');
    position: absolute;
    right: 0px;
    top: 0px;
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding-top: 6px;
    padding-left: 0.9px;
    background-color: rgba(238, 238, 238, 1);
  }
}

.emoji {
  background-image: url('../src/image/emoji.png');
  background-position: center;
  background-repeat: no-repeat;
  height: 60px;
}

.formio-component-hidden {
  display: none;
}

.formio-component-container.formio-component-label-hidden {
  display: none;
}
