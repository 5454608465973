.progress {
  @apply flex h-4 overflow-hidden text-xs bg-gray-200 rounded shadow-sm;
}

.progress-bar {
  @apply flex flex-col justify-center text-white text-center whitespace-nowrap bg-primary transition-all duration-500 ease-linear;
}

@keyframes ldio-stvxfdp36os {
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
}
.ldio-stvxfdp36os div { box-sizing: border-box!important }
.ldio-stvxfdp36os > div {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 14px;
  left: 14px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: var(--secondary-color,#0a0a0a) transparent var(--secondary-color,#0a0a0a) transparent;
  animation: ldio-stvxfdp36os 1.3513513513513513s linear infinite;
}
.ldio-stvxfdp36os > div:nth-child(2) { border-color: transparent }
.ldio-stvxfdp36os > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-stvxfdp36os > div:nth-child(2) div:before, .ldio-stvxfdp36os > div:nth-child(2) div:after { 
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 24px;
  background: var(--secondary-color, #0a0a0a);
  border-radius: 50%;
  box-shadow: 0 64px 0 0 var(--secondary-color,#0a0a0a);
}
.ldio-stvxfdp36os > div:nth-child(2) div:after { 
  left: -8px;
  top: 24px;
  box-shadow: 64px 0 0 0 var(--secondary-color,#0a0a0a);
}
.loadingio-spinner-dual-ring-s39sjgrqux {
  width: 74px;
  height: 74px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-stvxfdp36os {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.74);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-stvxfdp36os div { box-sizing: content-box; }