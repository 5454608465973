/* Base class - Easily usable on <ul>, <ol>, or <div> */

.list-group {
  @apply flex flex-col pl-0 mb-5;
}

/* Interactive list items
Use anchor or button elements instead of `li`s or `div`s to create interactive
list items. Includes an extra `.active` modifier class for selected items.
 */

.list-group-item-action {
  @apply w-full;
  text-align: inherit; /* For `<button>`s (anchors inherit) */
  &:hover,
  &:focus {
    @apply z-1 text-gray-700 bg-gray-100;
  }

  &:active {
    @apply z-1 text-gray-900 bg-white;
  }
}

/* Individual list items
Use on `li`s or `div`s within the `.list-group` parent.
*/

.list-group-item {
  @apply relative block py-2 px-4 -mb-px bg-white border-1 border-solid border-gray-300;

  &.list-group-header {
    @apply bg-gray-200;
  }

  &:first-child {
    @apply rounded-t;
  }

  &:last-child {
    @apply mb-0 rounded-b;
  }

  &.disabled,
  &:disabled {
    @apply text-gray-600 bg-white;
  }

  &.active {
    @apply z-2  text-white bg-primary border-primary;
  }
}

/* Flush list items
Remove borders and border-radius to keep list group items edge-to-edge. Most
useful within other components (e.g., cards).
*/

.list-group-flush {
  .list-group-item {
    @apply border-l-0 border-r-0 rounded;

    &:last-child {
      @apply -mb-px;
    }
  }

  &:first-child {
    .list-group-item:first-child {
      @apply border-t-0;
    }
  }

  &:last-child {
    .list-group-item:last-child {
      @apply mb-0 border-b-0;
    }
  }
}

ol {
  list-style-type: decimal;
  list-style-position: inside;
}

ul .check-icon {
  @apply mb-2;
  position: relative;
  list-style-type: none;
  padding-left: 30px;

  &:before {
    position: absolute;
    content: url('../../svg/check.svg');
    left: 0px;
    top: 5px;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding-right: 7px;
    line-height: 1px;
  }
}
ul .check-icon-large {
  @apply mb-2;
  position: relative;
  list-style-type: none;
  padding-left: 30px;
  &:before {
    position: absolute;
    content: url('../../svg/check.svg');
    left: 0px;
    top: 5px;
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    padding-right: 7px;
    line-height: 1px;
  }
}

ul .cross-icon {
  @apply mb-2;

  position: relative;
  list-style-type: none;
  padding-left: 30px;

  &:before {
    position: absolute;
    content: url('../../svg/cross.svg');
    left: 0px;
    top: 5px;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding-right: 7px;
    line-height: 1px;
  }
}

ul .clock-icon {
  @apply mb-2;

  position: relative;
  list-style-type: none;
  padding-left: 36px;
  padding-top: 2px;
  &:before {
    position: absolute;
    content: url('../../svg/clock.svg');
    left: 0px;
    top: 4px;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding-right: 7px;
    line-height: 1px;
  }
}

ul .info-icon {
  @apply mb-2;

  position: relative;
  list-style-type: none;
  padding-left: 36px;
  padding-top: 2px;
  &:before {
    position: absolute;
    content: url('../../svg/info.svg');
    left: 0px;
    top: 4px;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding-right: 7px;
    line-height: 1px;
  }
}
ul .question-icon {
  @apply mb-2;

  position: relative;
  list-style-type: none;
  padding-left: 36px;
  padding-top: 2px;
  &:before {
    position: absolute;
    content: url('../../svg/question-stroke.svg');
    left: 0px;
    top: 4px;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding-right: 7px;
    line-height: 1px;
  }
}

ul .phone-icon {
  @apply mb-2;

  position: relative;
  list-style-type: none;
  padding-left: 36px;
  padding-top: 1px;
  &:before {
    position: absolute;
    content: url('../../svg/phone.svg');
    left: 0px;
    top: 3px;
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    padding-right: 7px;
    line-height: 1px;
  }
}
